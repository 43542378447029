import request from '@/utils/request'

//实习考勤签到
export function addpracticeattendance(data) {
    return request({
        url: '/portal/practice-attendance/add',
        method: 'post',
        data
    })
}

//获取学生实习考勤列表
export function getpracticeattendancestudentlist(params) {
    return request({
        url: '/portal/practice-attendance/get-student-list',
        method: 'GET',
        params
    })
}

//获取实习考勤列表
export function getpracticeattendancelist(params) {
    return request({
        url: '/portal/practice-attendance/get-list',
        method: 'GET',
        params
    })
}

//获取实习考勤汇总数据
export function getpracticeattendanceinfo(params) {
    return request({
        url: '/portal/practice-attendance/get-info',
        method: 'GET',
        params
    })
}